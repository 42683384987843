import React, { useEffect, useMemo, useState } from 'react';
import Styles from './style';
import { Button, Input } from 'antd';
import { useLibraries } from '../../hooks/useLibraries';
import { CloseOutlined, PlusOutlined, SearchOutlined, StarOutlined } from '@ant-design/icons';
import { Divider } from '../../../../componentsV2/SideBar/style';
import { Folder as FolderIcon } from '../../../shared/components/Icons/Folder';
import { NewFolderModal } from '../../../../pages/libraries/components/NewFolderModal';
import { Check } from '../../../shared/components/Icons/Check';
import { addItem, removeItem } from '../../libraries.service';
import { useToast } from '../../../../hooks/useToast';
import { UpdateLoader } from '../../../../componentsV2/UpdateLoader';

const {
  Container,
  Content,
  Footer,
  Heading,
  Header,
  SearchContainer,
  SectionContainer,
  FoldersList,
  Folder,
  NewFolderLabel,
  FolderName,
  SectionTitle,
  Modal,
  NewFolder,
} = Styles;

interface Props {
  loading?: boolean;
}

type FolderManage = { id: string; name: string; selected: boolean; libraryId: string };

export const ManageInsightsFolderModal: React.FC<Props> = ({}) => {
  const [markedFoldersIds, setMarkedFoldersIds] = useState<string[]>([]);
  const {
    userLibrary,
    organizationLibrary,
    quitManagingInsight,
    insightSelected,
    refetchLibraries,
  } = useLibraries();
  const [showNewFolderOptions, setShowNewFolderOptions] = useState({
    show: false,
    location: 'mine',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [pendingChanges, setPendingChanges] = useState<{ add: string[]; remove: string[] }>({
    add: [],
    remove: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const { success, error: errorToast } = useToast();

  useEffect(() => {
    if (insightSelected.insight) {
      const folders = insightSelected.insight.folders.map(folder => folder.id);
      setMarkedFoldersIds(folders);
    }
  }, [insightSelected.insight]);

  const isOpen = Boolean(insightSelected.insight);

  const handleFolderClicked = async (libraryId: string, folderId: string) => {
    if (insightSelected.insight) {
      if (markedFoldersIds.includes(folderId)) {
        setMarkedFoldersIds(markedFoldersIds.filter(ids => ids !== folderId));
        setPendingChanges(prev => {
          const addIndex = prev.add.indexOf(folderId);
          if (addIndex > -1) {
            prev.add.splice(addIndex, 1);
          } else {
            prev.remove.push(folderId);
          }
          return { ...prev };
        });
      } else {
        setMarkedFoldersIds([...markedFoldersIds, folderId]);
        setPendingChanges(prev => {
          const removeIndex = prev.remove.indexOf(folderId);
          if (removeIndex > -1) {
            prev.remove.splice(removeIndex, 1);
          } else {
            prev.add.push(folderId);
          }
          return { ...prev };
        });
      }
    }
  };

  const handleSave = async () => {
    if (insightSelected.insight && userLibrary) {
      try {
        setIsLoading(true);
        for (const folderId of pendingChanges.add) {
          await addItem(folderId, insightSelected.insight.id, insightSelected.insight.type);
        }
        for (const folderId of pendingChanges.remove) {
          await removeItem(userLibrary.id, folderId, insightSelected.insight.id);
        }
        success(`Changes saved successfully`);
      } catch (e) {
        errorToast((e as Error).message);
      } finally {
        refetchLibraries();
        setIsLoading(false);
        setPendingChanges({ add: [], remove: [] });
        quitManagingInsight();
      }
    }
  };

  const userFolders: FolderManage[] = useMemo(() => {
    if (userLibrary) {
      return userLibrary.folders
        .filter(folder => folder.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .map(folder => {
          return {
            id: folder.id,
            name: folder.name,
            libraryId: userLibrary.id,
            selected: markedFoldersIds.includes(folder.id),
          };
        });
    }
    return [];
  }, [userLibrary, searchTerm]);

  const organizationFolders = useMemo(() => {
    if (organizationLibrary) {
      return organizationLibrary.folders
        .filter(folder => folder.name.toLowerCase().includes(searchTerm.toLowerCase()))
        .map(folder => {
          return {
            id: folder.id,
            name: folder.name,
            libraryId: organizationLibrary.id,
            selected: markedFoldersIds.includes(folder.id),
          };
        });
    }
    return [];
  }, [organizationLibrary, searchTerm]);

  const closeNewFolderModal = () => setShowNewFolderOptions({ show: false, location: 'mine' });

  return (
    <Modal
      open={isOpen}
      title={false}
      closable={false}
      footer={false}
      style={{ padding: '0px' }}
      // bodyStyle={{ padding: '0px' }}
      destroyOnClose
      centered
    >
      <NewFolderModal
        open={showNewFolderOptions.show}
        location={showNewFolderOptions.location}
        onCancel={closeNewFolderModal}
        onFolderCreated={closeNewFolderModal}
      />
      <Header>
        <StarOutlined />
        <Heading>Add to folders</Heading>
        <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => quitManagingInsight()} />
      </Header>
      {insightSelected?.isFetching ? (
        <div style={{ display: 'flex', height: '200px', justifyContent: 'center' }}>
          <UpdateLoader min />
        </div>
      ) : (
        <Container>
          <Content></Content>
          <SearchContainer>
            <Input
              value={searchTerm}
              onChange={ev => setSearchTerm(ev.target.value)}
              style={{ height: '42px' }}
              prefix={<SearchOutlined />}
              placeholder="Search folder"
            />
          </SearchContainer>
          <SectionContainer>
            <SectionTitle>My folders</SectionTitle>
            <Divider />
            <FoldersList>
              <NewFolder onClick={() => setShowNewFolderOptions({ show: true, location: 'mine' })}>
                <PlusOutlined style={{ color: '#ff6c54' }} />
                <NewFolderLabel>New folder</NewFolderLabel>
              </NewFolder>
              {userFolders.map(folder => {
                const checked = markedFoldersIds.includes(folder.id);
                return (
                  <Folder
                    onClick={() => handleFolderClicked(folder.libraryId, folder.id)}
                    key={'users-folder-' + folder.id}
                  >
                    <FolderIcon />
                    <FolderName>{folder.name}</FolderName>
                    {checked && <Check />}
                  </Folder>
                );
              })}
            </FoldersList>
          </SectionContainer>
          <SectionContainer style={{ marginTop: '16px' }}>
            <SectionTitle>Workspace folders</SectionTitle>
            <Divider />
            <FoldersList>
              <NewFolder
                onClick={() => setShowNewFolderOptions({ show: true, location: 'my-organization' })}
              >
                <PlusOutlined style={{ color: '#ff6c54' }} />
                <NewFolderLabel>New folder</NewFolderLabel>
              </NewFolder>
              {organizationFolders.map(folder => {
                const checked = markedFoldersIds.includes(folder.id);
                return (
                  <Folder
                    onClick={() => handleFolderClicked(folder.libraryId, folder.id)}
                    key={'org-folder-' + folder.id}
                  >
                    <FolderIcon />
                    <FolderName>{folder.name}</FolderName>
                    {checked && <Check />}
                  </Folder>
                );
              })}
            </FoldersList>
          </SectionContainer>

          <Footer>
            <Button
              type="primary"
              children="Apply Changes"
              loading={isLoading}
              onClick={handleSave}
              disabled={pendingChanges.add.length === 0 && pendingChanges.remove.length === 0}
              style={{ flex: 2 }}
            />
            <Button
              type="default"
              children="Close"
              onClick={() => quitManagingInsight()}
              style={{ width: '100px' }}
            />
          </Footer>
        </Container>
      )}
    </Modal>
  );
};
