import React, { useEffect, useMemo, useState } from 'react';
import { UpcomingAndPastMeetings } from './UpcomingAndPastMeetings';
import { Card } from '../../../../components/Card';
import { Topics } from './Topics';
import { SummaryHistory } from './SummaryHistory';
import { Stakeholders } from './Stakeholders';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../../redux/selectors';
import { Frameworks } from './Frameworks';
import Styles from './style';
import TabComponent from '../../../../features/shared/components/TabV2';

const { Contents } = Styles;
const { Tab, TabItem } = TabComponent;

export const ACCOUNT_DATE_FORMAT = 'MMM dd, yyyy';

interface Props {
  currentTab?: string;
  onTabSelected: (tab: string) => unknown;
}

export const LeftTabs: React.FC<Props> = ({ currentTab = 'past-meetings', onTabSelected }) => {
  const [selectedTab, setSelectedTab] = useState('past-meetings');

  const user = useSelector(selectUser);

  useEffect(() => {
    setSelectedTab(currentTab);
  }, [currentTab]);

  const handleSelectedTab = (selectedTab: string) => {
    if (selectedTab !== 'frameworks')
      document.dispatchEvent(new CustomEvent('stop-framework-polling'));
    setSelectedTab(selectedTab);
    onTabSelected(selectedTab);
  };

  const items = useMemo(() => {
    const partialTabs = [
      {
        key: 'past-meetings',
        label: (
          <TabItem isActive={selectedTab === 'past-meetings'} label="Upcoming & Past Meetings" />
        ),
        children: <UpcomingAndPastMeetings onSummary={() => setSelectedTab('summary-history')} />,
      },
      {
        key: 'summary-history',
        label: <TabItem isActive={selectedTab === 'summary-history'} label="AI Summary History" />,
        children: <SummaryHistory active={selectedTab === 'summary-history'} />,
      },
      {
        key: 'stakeholders',
        label: <TabItem isActive={selectedTab === 'stakeholders'} label="Stakeholders" />,
        children: <Stakeholders />,
      },
    ];

    if (user?.email.includes('@update.ai')) {
      partialTabs.push({
        key: 'topics',
        label: <TabItem isActive={selectedTab === 'topics'} label="Topics" />,
        children: <Topics />,
      });
    }

    partialTabs.push({
      key: 'frameworks',
      label: <TabItem isActive={selectedTab === 'frameworks'} label="Frameworks" />,
      children: <Frameworks />,
    });
    return partialTabs;
  }, [selectedTab, user?.id]);

  return (
    <Card>
      <Contents>
        <Tab items={items} activeKey={selectedTab} onChange={handleSelectedTab} />
      </Contents>
    </Card>
  );
};
