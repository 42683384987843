import React from 'react';
import { Button, Divider, Dropdown, Table } from 'antd';
import { Cell, Header, HeaderCell, Row } from '../../../../../../features/shared/components/Table';
import { format } from 'date-fns';
import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';

import Style from './style';
const { Container, NoDataContainer, Message, Title, Detail } = Style;

interface Props {
  data: any[];
  loading?: boolean;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

const FrameworkTable: React.FC<Props> = ({ data, loading, onEdit, onDelete }) => {
  const items = (id: string) => [
    {
      key: '1',
      label: <a onClick={() => onEdit(id)}>Edit</a>,
    },
    {
      key: '-',
      label: <Divider style={{ margin: '0px', width: '100%' }} />,
      disabled: true,
    },
    {
      key: '2',
      label: (
        <a
          style={{ color: 'red', display: 'flex', alignItems: 'center', gap: '5px' }}
          onClick={() => onDelete(id)}
        >
          {loading ? (
            <Button loading={loading} />
          ) : (
            <>
              <DeleteOutlined style={{ color: 'red' }} /> Delete
            </>
          )}
        </a>
      ),
    },
  ];

  const columns: ColumnsType<any> = [
    {
      title: 'Framework Name',
      key: 'name',
      render: (v, r) => <>{r.name}</>,
    },
    {
      title: 'Meeting Type Assigned',
      key: 'meeting-type',
      width: '300px',
      render: (v, r) => <>{r.meetingType}</>,
    },
    {
      title: 'Created At',
      key: 'created-at',
      width: '300px',
      render: (v, r) => (
        <div>
          <>{format(new Date(r.createdAt), 'dd MMMM, yyyy - hh:mm')} </>
          <>{format(new Date(r.createdAt), 'aaaa').toUpperCase()}</>
        </div>
      ),
    },
    {
      key: 'action',
      width: '50px',
      render: (v, r) => (
        <Dropdown menu={{ items: items(r.id) }} placement="bottomRight" disabled={loading}>
          <Button loading={loading} icon={<MoreOutlined />} />
        </Dropdown>
      ),
    },
  ];

  const components = {
    header: {
      row: Header,
      cell: HeaderCell,
    },
    body: {
      row: Row,
      cell: Cell,
    },
  };

  return (
    <Container>
      {data.length > 0 || loading ? (
        <Table
          loading={loading}
          columns={columns}
          pagination={false}
          dataSource={data}
          components={components}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          rowKey={(e: any) => {
            return e.id;
          }}
        />
      ) : (
        <NoDataContainer>
          <Message>
            <Title>No data</Title>
            <Detail>It seems that there are no frameworks created yet.</Detail>
          </Message>
        </NoDataContainer>
      )}
    </Container>
  );
};

export default FrameworkTable;
