import styled from 'styled-components';

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin-top: 20px;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  h2 {
    margin: 0px;
    color: #282828;
    font-family: 'Cera Pro';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
`;

export default { ModalContent, Item };
