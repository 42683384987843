import { useState } from 'react';
import { Button, Spin } from 'antd';
import { FrameworkExecution } from '../../../../../features/frameworks/type';
import { FrameworkCard } from '../../../../../features/frameworks/components/Card';
import { useToast } from '../../../../../hooks/useToast';
import { FrameworksHeader } from '../../../../../features/frameworks/components/FrameworksHeader';
import { coreService } from '../../../../../services/core/core-service';
import { format } from 'date-fns';

import Styles from './styles';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { capitalize } from 'lodash';
const { Container, EmptyView, Cards } = Styles;

interface InternalFrameworkProps {
  lastExecution: FrameworkExecution | null;
  selectedFrameworkName: string | null;
  loading: boolean;
  onGenerate: () => void;
  updateLastExecution: (args: { id: string; value: string }) => void;
}

const InternalFramework: React.FC<InternalFrameworkProps> = ({
  lastExecution,
  loading,
  selectedFrameworkName,
  onGenerate,
  updateLastExecution,
}) => {
  if (loading) {
    return (
      <Container>
        <Cards style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Spin />
        </Cards>
      </Container>
    );
  }

  if (!lastExecution?.id)
    return (
      <Container>
        <Cards style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <EmptyView
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '8px',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <span style={{ width: '500px', textAlign: 'center' }}>
              Click the “Generate” button to automatically create notes for framework:{' '}
              {selectedFrameworkName || ''} from this meeting.
            </span>
            <Button onClick={onGenerate} type="primary">
              Generate
            </Button>
          </EmptyView>
        </Cards>
      </Container>
    );

  if (lastExecution?.isProcessing) {
    return (
      <Container>
        <Cards style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <EmptyView
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}
          >
            <span style={{ width: '500px', textAlign: 'center' }}>
              Notes are being generated for framework: {selectedFrameworkName || ''}
            </span>
            <Spin />
          </EmptyView>
        </Cards>
      </Container>
    );
  }

  return (
    <Cards>
      {lastExecution &&
        lastExecution?.values.map(v => (
          <FrameworkCard
            key={'fw-card-qst-id' + v.questionId}
            id={v.questionId}
            title={v.question}
            value={v.answer}
            loading={loading}
            onSave={updateLastExecution}
          />
        ))}
    </Cards>
  );
};

export const Frameworks: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [lastExecution, setLastExecution] = useState<FrameworkExecution | null>(null);
  const id = window.location.pathname.replace('/accounts/', '');

  const { selectedAccount } = useAccountOverview();
  const { success } = useToast();

  if (lastExecution === undefined) {
    return (
      <Container
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '600px' }}
      >
        <EmptyView
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}
        >
          <Spin />
        </EmptyView>
      </Container>
    );
  }

  const updateLastExecution = (args: { id: string; value: string }) => {
    if (!lastExecution) return;
    const updatedExecution = {
      ...lastExecution,
      values: lastExecution.values.map(v =>
        v.questionId === args.id ? { ...v, answer: args.value } : v,
      ),
    };
    setLastExecution(updatedExecution);
    setLoading(true);
    coreService.updateAccountFrameworkExecution(id, updatedExecution).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Container>
      <FrameworksHeader
        setLastExecution={setLastExecution}
        fetchLastExecution={externalId => {
          return coreService.fetchAccountFrameworksByExternalId(id, externalId);
        }}
        onGenerate={externalId => {
          return coreService.generateAccountFrameworksByExternalId(id, externalId);
        }}
        onCopy={(name, createdAt) => {
          navigator.clipboard.writeText(
            `Account: ${capitalize(selectedAccount.name)}\n\n${name} - ${format(
              new Date(createdAt),
              'MMMM dd, yyyy hh:mm aa',
            )}\n\n${lastExecution?.values.map(v => `${v.question}:\n ${v.answer}`).join('\n\n')}`,
          );
          success('Copied to clipboard');
        }}
        disabled={!!lastExecution?.isProcessing || loading}
        key={'accounts-frameworks-header'}
      >
        <InternalFramework
          loading={loading}
          selectedFrameworkName={null}
          lastExecution={lastExecution}
          onGenerate={() => 'generate'}
          updateLastExecution={updateLastExecution}
        />
      </FrameworksHeader>
    </Container>
  );
};
