import { v4 } from 'uuid';
import { Question } from './type';

export const MEDDICQuestions = () => {
  return [
    {
      id: v4(),
      position: '1',
      value: 'Metrics',
      context: 'Quantifiable measures of success that your solution can help the customer achieve',
    },
    {
      id: v4(),
      position: '2',
      value: 'Economic Buyer',
      context:
        'positionentifying the key decision-maker who has the financial authority to approve the purchase',
    },
    {
      id: v4(),
      position: '3',
      value: 'Decision Criteria',
      context:
        'Standards or requirements that the customer will use to evaluate and choose among different solutions',
    },
    {
      id: v4(),
      position: '4',
      value: 'Decision Process',
      context: 'Steps and processes that the organization follows to make a purchase decision',
    },
    {
      id: v4(),
      position: '5',
      value: 'Positionentify Pain',
      context:
        "Uncovering the customer's pain points or challenges that your product or service can address",
    },
    {
      id: v4(),
      position: '6',
      value: 'champion',
      context:
        "An internal advocate within the customer's organization who believes in the value of your solution and can influence others",
    },
    {
      id: v4(),
      position: '7',
      value: 'Competition',
      context: "Awareness of competitors that are also vying for the customer's business",
    },
  ] as unknown as Question[];
};
